/* eslint-disable vue/require-v-for-key */
<template>
  <div class="create-page-main">
    <div class="create-form">
      <div class="form-title p-4 mb-4">タイミングメール編集</div>
      <b-form :disabled="isLoading">
        <b-form-group id="input-group-5" label-for="input-5">
          <label class="col-sm-12 font-weight-bold custom-text">NO</label>
          <div class="px-3 w-100">
            <b-form-input
              v-model="NO"
              disabled
              required
              style="color: black"
            ></b-form-input>
          </div>
        </b-form-group>
        <b-form-group id="input-group-5" label-for="input-5">
          <label class="col-sm-12 font-weight-bold custom-text"
            >管理名※ユーザーには公開されません<span class="textMust"
              >（必須）</span
            ></label
          >
          <div class="px-3 w-100">
            <b-form-input v-model="management_name" required></b-form-input>
          </div>
        </b-form-group>
        <b-form-group id="input-group-5" label-for="input-5">
          <label class="col-sm-12 font-weight-bold custom-text"
            >メール送信者名<span class="textMust">（必須）</span></label
          >
          <div class="px-3 w-100">
            <b-form-input v-model="sender_name" required></b-form-input>
          </div>
        </b-form-group>
        <b-form-group id="input-group-5" label-for="input-5">
          <label class="col-sm-12 font-weight-bold custom-text"
            >配信メールタイトル（100文字以内）<span class="textMust"
              >（必須）</span
            ></label
          >
          <div class="px-3 w-100">
            <b-form-input
              v-model="title"
              required
              maxlength="100"
            ></b-form-input>
          </div>
        </b-form-group>
        <b-form-group id="input-group-8" label-for="input-8">
          <label class="col-sm-12 font-weight-bold custom-text"
            >配信メール本文<span class="textMust">（必須）</span></label
          >
          <div class="px-3 w-100">
            <input
              type="file"
              hidden
              ref="selectFile"
              id="idSelectFile"
              accept=".pdf"
              @change="changePDF($event)"
            />
            <vue-editor
              useCustomImageHandler
              @image-added="handleImageAdded"
              @image-removed="handleImageRemove"
              v-model="content"
              class="mb-4"
            ></vue-editor>
          </div>
        </b-form-group>

        <b-form-group id="input-group-8" label-for="input-8">
          <label class="col-sm-12 font-weight-bold custom-text"
            >タイミングメール利用<span class="textMust">（必須）</span></label
          >
          <div class="ml-4">
            <div class="row align-items-center flex-nowrap mt-3">
              <b-form-radio
                v-model="use_timing_mail"
                name="some-radios"
                value="0"
                @change="changeValue(0)"
                >利用しない</b-form-radio
              >
            </div>
            <div class="row align-items-center flex-nowrap mt-3">
              <b-form-radio
                v-model="use_timing_mail"
                name="some-radios"
                :value="1"
                @change="changeValue(1)"
                >トリガー発動後に 即送信</b-form-radio
              >
            </div>
            <div class="row align-items-center mt-3">
              <div class="col-auto col-lg-2 px-0">
                <b-form-radio
                  v-model="use_timing_mail"
                  name="some-radios w-auto"
                  :value="2"
                  @change="changeValue(2)"
                  >トリガー発動後</b-form-radio
                >
              </div>
              <div class="col-lg-2 px-lg-0">
                <b-form-input
                  :disabled="use_timing_mail !== 2"
                  class="text-center w-100"
                  v-model="minutes"
                  required
                ></b-form-input>
              </div>
              <span class="col-auto pl-4 px-lg-1">分後に送信 </span>
            </div>
          </div>
        </b-form-group>

        <div class="d-flex justify-content-center mt-4 py-4">
          <b-button
            v-on:click.prevent="CreateTimeEmail()"
            :disabled="isLoading"
            variant="info"
            class="mr-4"
          >
            <span v-show="!isLoading"> 編集内容を反映 </span>
            <div
              v-show="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <span class="sr-only">ローディング...</span>
            </div>
          </b-button>
          <b-button variant="secondary" class="mr-4" v-on:click="returnList()"
            >登録完了</b-button
          >
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapGetters, mapActions } from "vuex";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
import { Constants } from "../../utils/constants.js";
export default {
  name: "Create_TimingMail",
  components: {
    VueEditor,
    // draggable,
  },
  data() {
    return {
      management_name: "",
      sender_name: "",
      isLoading: false,
      title: "",
      content: "",
      use_timing_mail: 0,
      minutes: null,
      dataDetail: {
        id: "",
        shop_id: "",
      },
      NO: "",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {
    this.NO = this.$route.query.no;
    const { params } = this.$route;
    this.dataDetail.id = params.id;
    this.dataDetail.shop_id = this.shop_id;
    if (this.dataDetail.id !== undefined) {
      this.getTimingMailById(this.dataDetail);
    }
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  mounted() {
    let container = document.getElementsByClassName("ql-toolbar")[0];
    let item = document.createElement("span");
    item.classList.add("ql-formats");
    let input = document.createElement("button");
    input.addEventListener("click", this.toggleModal);
    input.setAttribute("type", "button");
    input.classList.add("ql-test");
    input.innerHTML = "PDF";
    item.appendChild(input);
    container.appendChild(item);
  },
  computed: {
    ...mapGetters(["timingMailById", "success", "message", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
      }
    },
    timingMailById() {
      this.management_name = this.timingMailById.management_name;
      this.sender_name = this.timingMailById.sender_name;
      this.title = this.timingMailById.title;
      this.content = this.timingMailById.content;
      this.use_timing_mail = this.timingMailById.use_timing_mail;
      this.minutes = this.timingMailById.minutes;
    },
  },
  methods: {
    ...mapActions({ createTimingMail: "createTimingMail" }),
    ...mapActions({ getTimingMailById: "getTimingMailById" }),
    ...mapActions({ updateTimingMailByID: "updateTimingMailByID" }),
    async CreateTimeEmail() {
      this.isLoading = true;
      let shop_id = this.shop_id;
      const formCreate = {
        id: this.$route.params.id,
        shop_id: shop_id,
        management_name: this.management_name,
        sender_name: this.sender_name,
        title: this.title,
        content: this.content,
        use_timing_mail: this.use_timing_mail,
        minutes: this.minutes,
      };
      if (formCreate.id === undefined) {
        const request = await this.createTimingMail(formCreate);
        if (request.success === true) {
          this.$router.go(-1);
        } else {
          this.isLoading = false;
          this.$toasted.error(request.message);
        }
      } else {
        const request = await this.updateTimingMailByID(formCreate);
        if (request.success === true) {
          this.$router.go(-1);
        } else {
          this.isLoading = false;
          this.$toasted.error(request.message);
        }
      }
    },
    returnList() {
      this.$router.go(-1);
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        this.$toasted.error("画像がjpg、jpeg、png　形式である必要があります。");
      } else {
        if (file.size / 1024 / 1024 > 10) {
          this.$toasted.error("画像1枚につき10MBまで");
          resetUploader();
        } else {
          var formData = new FormData();
          formData.append("image", file);
          formData.append("id", this.shop_id);

          const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
          const method = "POST";
          await Api.adminRequestServer({
            method,
            url,
            data: formData,
          })
            .then((response) => {
              const url = response.data.data; // Get url from response
              Editor.insertEmbed(cursorLocation, "image", url);
              resetUploader();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    handleImageRemove(file) {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: file,
      });
    },
    toggleModal() {
      document.getElementById("idSelectFile").click();
    },
    async changePDF(e) {
      var formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("id", this.shop_id);
      if (e.target.files[0].size / 1024 / 1024 > 10) {
        this.$toasted.error("PDFファイルは10MBを超えこえてはなりません");
      } else {
        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            this.content +=
              '<iframe class="ql-pdf" frameborder="0" allowfullscreen="true" src="' +
              url +
              '"></iframe><p><br></p>';
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    changeValue(id) {
      if (id !== 2) {
        this.minutes = "";
      }
    },
  },
};
</script>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.custom-text {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
</style>
